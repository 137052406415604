/*------------------------------------
    STYLE.CSS
	DAVE DRIESMANS (@davedriesmans)
	v1 - 29 october 2014

    DESIGN ONLY
    common styles can be moved to style-base

------------------------------------ */
/**
 * Setup
 */
/*------------------------------------
   COLORS
----------------------------------- */
/*------------------------------------
   FONTS
------------------------------------ */
/* @font-face {
	font-family: 'icomoon';
	src:url('../fonts/icomoon.eot');
	src:url('../fonts/icomoon.eot?#iefix') format('embedded-opentype'),
		url('../fonts/icomoon.woff') format('woff'),
		url('../fonts/icomoon.ttf') format('truetype'),
		url('../fonts/icomoon.svg#icomoon') format('svg');
	font-weight: normal;
	font-style: normal; }
*/
/*------------------------------------
   WIDTH
----------------------------------- */
body {
  background: #FFF;
  font-family: "proxima-nova", sans-serif;
}

h1, h2, h3, h4, h5,
.panel h1, .panel h2, .panel h3, .panel h4 {
  color: #eb7e1c;
  font-family: "proxima-nova", sans-serif;
  font-weight: 800;
}

a {
  color: #eb7e1c;
}

a:hover {
  color: #454444;
}

/* ------------------ HEADER  ------------------  */
.contain-to-grid {
  background: #FFF;
  border-bottom: 1px solid #a8a8a8;
}

.top-bar {
  background: transparent;
  color: #fff;
  font-size: 15px;
  position: relative;
  width: 100%;
  z-index: 999;
  height: auto;
}

/* logo */
.top-bar .name h1 a.logo {
  float: left;
  width: 95px;
  height: 55px;
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
  font-size: 0;
  margin: 10px 0 0 10px;
  padding: 0;
  background-size: cover;
  background-image: url("../images/common/logo.png");
  background-repeat: no-repeat;
}

.top-bar.expanded .name h1 a.logo {
  background-image: url("../images/common/logo_white.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  .top-bar .name h1 a.logo {
    margin-top: 0;
    background-image: url("../images/common/logo@2x.png");
  }
  .top-bar.expanded .name h1 a.logo {
    background-image: url("../images/common/logo_white@2x.png");
  }
}

/* nav */
.top-bar .top-bar-section {
  display: none;
}

@media only screen and (min-width: 64.0625em) {
  .top-bar .top-bar-section {
    display: block;
  }
}

.top-bar.expanded .top-bar-section {
  display: block;
}

.top-bar-section ul li > a {
  font-family: "proxima-nova", sans-serif;
  font-weight: 200;
}

.top-bar-section ul li {
  background: transparent;
  color: #a8a8a8;
}

.top-bar-section ul li a {
  color: #454444;
  font-size: 13px;
}

.top-bar-section li:not(.has-form) a:not(.button),
.top-bar-section li.active:not(.has-form) a:not(.button) {
  background: transparent;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
}

.top-bar-section li.active:not(.has-form) a:not(.button) {
  color: #eb7e1c;
}

.top-bar-section li:not(.has-form) a:not(.button):hover,
.top-bar-section li.active:not(.has-form) a:not(.button):hover {
  background: rgba(235, 126, 28, 0.2);
  color: #eb7e1c;
  opacity: 1;
}

.top-bar-section .has-dropdown > a:after {
  border-color: #a8a8a8 transparent transparent transparent;
}

/* nav: dropdown */
/* resets for mobile */
.top-bar-section .has-dropdown ul.dropdown,
.top-bar-section .right li ul.dropdown {
  display: block;
  height: 40px;
  top: 140px;
  left: 0;
  border: 0;
  width: 300px;
}

.top-bar-section .right li ul.dropdown li,
.top-bar-section .right li ul.dropdown li a {
  min-height: 40px;
  width: 270px;
  word-wrap: break-word;
  white-space: normal;
}

@media only screen and (min-width: 64.0625em) {
  .topmenu {
    position: relative;
    z-index: 3;
  }
  .topmenu .inline-list {
    position: absolute;
    right: 0;
    top: 10px;
  }
  .top-bar {
    z-index: 2;
  }
  .top-bar .name h1 a.logo {
    position: relative;
    z-index: 1;
    width: 165px;
    height: 95px;
    margin-bottom: 20px;
  }
  .top-bar-section {
    position: absolute;
    z-index: 2;
    bottom: 0;
    right: 0;
  }
}

/* ---nav : dropdown  normal */
.top-bar-section .right .dropdown li:not(.has-form) > a,
.top-bar-section .dropdown li:not(.has-form):not(.active) > a:not(.button) {
  color: #454444;
  border: 0;
  background: rgba(255, 255, 255, 0.7);
  width: 230px;
  font-size: 14px;
  min-height: 40px;
  padding: 5px 8px;
  border: 0;
  border-top: 1px solid rgba(168, 168, 168, 0.4);
  word-wrap: break-word;
  white-space: normal;
}

/* --- nav : dropdown active */
.top-bar-section .dropdown li.active:not(.has-form) a:not(.button) {
  background: #dbf0f2;
  color: #eb7e1c;
}

/* --- nav : dropdown hover */
.top-bar-section .dropdown li:not(.has-form):not(.active):hover > a:not(.button) {
  background-color: #eb7e1c;
  background: #eb7e1c;
}

/* sandwich */
.toggle-topbar.menu-icon {
  background-color: #eb7e1c;
}

.toggle-topbar.menu-icon a,
.toggle-topbar.menu-icon:hover a {
  color: #FFF;
}

/* ----------------- HEADER IMAGE  ------------------  */
.headerpic {
  clear: both;
  background: #ddd center center no-repeat;
  background-size: cover;
  text-align: center;
  position: relative;
  min-height: 450px;
  margin-bottom: 50px;
  z-index: 1;
}

.headerpic.home {
  min-height: 450px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.headerpic .row {
  width: 100%;
  min-height: 60px;
  background-color: rgba(255, 255, 255, 0.6);
  position: absolute;
  padding: 0;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 0;
}

.headerpic.home .row {
  background-color: transparent;
  min-height: 0;
  height: 0;
}

.headerpic .title {
  display: block;
  text-align: left;
  padding: 20px 20px 10px 0;
  padding-left: 12%;
}

.headerpic .headerpic-block {
  position: absolute;
  bottom: 70px;
  width: 80%;
  margin-left: 10%;
  padding: 20px 70px 0px 70px;
  text-align: left;
  font-size: 25px;
  text-align: center;
  background-color: rgba(21, 135, 167, 0.8);
}

.headerpic .headerpic-block h3 {
  color: #eb7e1c;
  font-weight: normal;
  text-align: center;
  font-size: 60px;
  font-weight: 800;
  line-height: 60px;
  color: #fff;
  font-family: "kepler-std", serif;
}

.headerpic .headerpic-block h4 {
  display: inline-block;
  padding: 5px 10px;
  color: #fff;
  font-weight: 600;
}

.headerpic .headerpic-block .button-container {
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
}

.headerpic .headerpic-block .button-container .btn {
  font-size: 13px;
}

.headerpic .headerpic-block .button-container .btn.button {
  box-shadow: none;
}

.breadcrumbs > * a {
  color: #eb7e1c;
}

.headerpic .title ul.breadcrumbs {
  position: relative;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0 0 5px 0;
}

/* ----------------- BUTTONS  ------------------  */
.button {
  background-color: #eb7e1c;
  border-radius: 3px;
}

.button.flat {
  padding: 5px 10px;
}

.button:hover {
  background-color: #c36511;
  border-radius: 3px;
}

/* ----------------- MAIN CONTENT  ------------------  */
.main-content {
  padding: 0 3%;
}

/* ----------------- MAIN CONTENT : HOME ------------------  */
.home-content-block {
  border-bottom: 1px solid #eee;
  padding: 40px 0;
}

.quote-author {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  color: #454444;
}

.panel h4,
.panel > :last-child {
  border-bottom: 1px solid #fff;
  padding-bottom: 15px;
  margin-bottom: 15px;
  font-weight: bold;
  letter-spacing: 0;
  color: #454444;
}

.home-content-block .panel {
  font-size: 15px;
  line-height: 21px;
  background: transparent;
  border: 0;
  text-align: center;
}

.home-content-block.box-shadow {
  -webkit-box-shadow: 0px 2px 4px 0px rgba(50, 50, 50, 0.45);
  -moz-box-shadow: 0px 2px 4px 0px rgba(50, 50, 50, 0.45);
  box-shadow: 0px 2px 4px 0px rgba(50, 50, 50, 0.45);
}

.home-content-block.wat {
  background-color: #FFF;
}

.home-content-block.wat h4 {
  padding-bottom: 0;
  text-align: center;
}

.home-content-block.wat h4:after {
  content: " ";
  display: block;
  width: 50px;
  height: 3px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  left: 0px;
  top: 5px;
  background-color: #eb7e1c;
}

.home-content-block .icon-block {
  display: block;
  text-align: center;
}

.icon-128 {
  height: 0;
  width: 128px;
  padding-top: 129px;
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
  font-size: 0;
  margin: 0 auto;
  background-repeat: no-repeat;
}

.icon-128.antenna {
  background: url("../images/icons/antenna-128.png");
}

.icon-128.steps {
  background: url("../images/icons/steps-128.png");
}

.icon-128.binocular {
  background: url("../images/icons/binocular-128.png");
}

.headerpic.hoe-1 {
  height: 550px;
  margin-bottom: 0px;
  background-color: transparent;
}

.selfiestick {
  background-size: cover;
}

.selfiestick img {
  max-height: 370px;
  margin-bottom: 30px;
}

.selfiestick-bg {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  background: transparent url("/images/front-header-images/front-selfiestick-bg.jpg");
  background-size: cover;
}

.selfiestick-bg ul {
  list-style-type: none;
}

.selfiestick-bg ul li {
  position: relative;
  padding-left: 10px;
}

.selfiestick-bg ul li:before {
  position: absolute;
  left: 0;
  content: "- ";
}

.whitepane {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 20px;
}

@media screen and (min-width: 300px) {
  .selfiestick-bg {
    text-align: left;
  }
}

/* ----------------- FOOTER  ------------------  */
.main-footer {
  background-color: #1587a7;
  color: #fff;
  padding-top: 50px;
  padding-bottom: 50px;
  margin: 0;
}

.main-footer h4 {
  font-size: 18px;
  color: #fff;
  letter-spacing: 0;
  font-weight: 600;
}

.main-footer .accent {
  margin: 40px 0;
  color: #fff;
}

.main-footer .accent a {
  font-size: 15px;
  color: #fff;
  font-weight: 400;
}

.main-footer p {
  font-size: 14px;
}

.main-footer .accent a {
  text-decoration: underline;
}

footer ul.logos {
  list-style: none;
  opacity: 1;
  background-color: white;
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #454444;
  border-radius: 5px;
}

footer ul.logos li {
  float: left;
  display: inline-block;
  width: 15%;
  margin-right: 10%;
}

footer ul.logos li .idewe-logo {
  margin-top: 14px;
}

@media only screen and (max-width: 760px) {
  footer ul.logos li .idewe-logo {
    margin-top: 0;
  }
}

footer .copyright {
  opacity: 0.7;
  text-align: right;
}

button, .button {
  background-color: #eb7e1c;
  border-color: #da7113;
  box-shadow: 2px 2px 0 white, 4px 4px 0 #eb7e1c;
}

button:hover, .button:hover {
  background-color: #c36511;
  border-color: #ab590f;
  box-shadow: 2px 2px 0 white, 4px 4px 0 #c36511;
}

.alert {
  padding: 20px;
  display: block;
  background-color: #EFEFEF;
  font-weight: bold;
}

.alert.alert-error {
  color: red;
}

.alert.alert-error > p {
  font-weight: inherit;
  margin: 0;
}

.doc-block .doc-block-title {
  background-position: center;
  background-size: cover;
}

.doc-block .doc-block-title i {
  display: none;
}

.doc-block:first-of-type .doc-block-title {
  background-image: url("../images/icons/doc_campaign.png");
}

.doc-block:nth-of-type(2) .doc-block-title {
  background-image: url("../images/icons/doc_example.png");
}

.doc-block:last-of-type .doc-block-title {
  background-image: url("../images/icons/doc_video.png");
}

.doc-block-title {
  position: relative;
  height: 200px;
  text-align: center;
  border-radius: 2px;
  color: #FFF;
}

.doc-block-title span {
  position: absolute;
  bottom: 5px;
  left: 0;
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0 10px;
}

.doc-block-title i {
  display: block;
  padding-top: 30px;
  font-size: 100px;
  transition: transform 1s ease;
  transform: scale(1, 1);
}

.doc-block-content {
  border: 1px solid #dfccb8;
  padding: 20px;
}

a.doc-block-item {
  position: relative;
  display: block;
}

a.doc-block-item p {
  color: #a8a8a8;
}

a.doc-block-item .label {
  float: right;
}

.topmenu {
  max-width: 62.5rem;
  margin: 10px auto 0 auto;
}

.topmenu .inline-list {
  font-size: 14px;
  overflow: inherit;
  margin-top: 0;
  margin-bottom: 0;
}

.topmenu a.button {
  margin-bottom: 0;
  padding: 5px 30px;
}

.topmenu .langMenu button.button {
  padding: 5px 2.33333rem 5px 1.11111rem;
  text-transform: uppercase;
}

.topmenu .langMenu button.button::after {
  border-color: #FFFFFF transparent transparent transparent;
  border-style: solid;
  border-width: 0.33333rem;
  right: 1rem;
  margin-top: -0.11111rem;
  margin-left: 5px;
  content: "";
  display: block;
  height: 0;
  position: absolute;
  top: 50%;
  width: 0;
}

.topmenu .langMenu .f-dropdown {
  width: auto;
}

.topmenu .langMenu .f-dropdown a {
  padding: 5px 2.33333rem 5px 1.11111rem;
  text-transform: uppercase;
}

.greeting {
  margin-top: 6px;
}

.greeting i {
  float: left;
  margin-right: 5px;
}

.button-flat {
  border: 0 none;
  box-shadow: none;
}

.button-flat:hover {
  box-shadow: none;
}

.button-flat.blue {
  background-color: #1A3547;
}

.row.event {
  border-bottom: 1px solid #eee;
  margin-top: 30px;
}

.row.event:last-child {
  border-bottom: 0 none;
}

.row.event h3 {
  margin-bottom: 0;
}

.event-datetime {
  color: gray;
}

.category-select a {
  display: block;
  line-height: 40px;
  border-radius: 5px;
}

.category-select.active {
  line-height: 40px;
}

.category-select.active a {
  background-color: #eb7e1c;
  color: #FFF;
}

.cta-container {
  margin-top: 10px;
}

/* Overons / About us  */
.overons-logo-idewe img {
  height: 123px;
  width: 300px;
}

@media only screen and (max-width: 640px) {
  .overons-logo-idewe img {
    height: auto;
  }
}

/* calendar icon */
.icon-calendar {
  background: white url("/images/icons/icon-calendar.gif") no-repeat top left;
  display: inline-block;
  width: 17px;
  height: 18px;
  margin-right: 5px;
}
